var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('b-form',{staticClass:"mx-auto",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.updateUserPassword.apply(null, arguments)}}},[_c('b-form-row',{staticClass:"mb-2",attrs:{"md":"auto"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-input-group',{ref:"old-password",staticClass:"mt-3",attrs:{"prepend":"Old Password"}},[_c('b-form-input',{attrs:{"type":_vm.passFieldType,"required":""},model:{value:(_vm.user.currentPassword),callback:function ($$v) {_vm.$set(_vm.user, "currentPassword", $$v)},expression:"user.currentPassword"}}),_c('b-input-group-append',[_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                    'form-control show_password_icon',
                    _vm.showPass ? 'i-Eye-Scan' : 'i-Eye-Visible'
                  ],on:{"click":_vm.showPassword}})])])],1)],1)],1),_c('b-form-row',{staticClass:"mb-2",attrs:{"md":"auto"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-input-group',{ref:"new-password",staticClass:"mt-3",attrs:{"prepend":"New Password"}},[_c('b-form-input',{attrs:{"type":_vm.passConfirmFieldType,"required":""},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}}),_c('b-input-group-append',[_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                    'form-control show_password_icon',
                    _vm.showConfirmPass ? 'i-Eye-Scan' : 'i-Eye-Visible'
                  ],on:{"click":_vm.showConfirmPassword}})])])],1)],1)],1),_c('div',{staticClass:"t-center my-4"},[_c('b-button',{staticClass:"w-50",attrs:{"variant":"success","type":"submit"}},[_c('span',{staticClass:"mx-auto font-weight-bold text-uppercase"},[_vm._v(" Update Password ")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }