<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="text-primary mb-0">Profile</h3>
      <div v-if="visibleSection === 'Profile'">
        <button
          class="btn btn-sm btn-success text-uppercase font-weight-bold"
          @click="toggleEditProfile"
        >
          Edit Profile
        </button>
      </div>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="card">
      <div class="card-body">
        <ul
          class="nav nav-tabs mb-4 font-weight-bold text-uppercase nav-justified"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-icon-pill"
              data-toggle="pill"
              @click="toggleVisibleSection('Profile')"
              role="tab"
              aria-controls="homePIll"
              aria-selected="true"
            >
              <i class="nav-icon i-Settings-Window mr-1"></i>Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-icon-pill"
              data-toggle="pill"
              @click="toggleVisibleSection('Password')"
              role="tab"
              aria-controls="profilePIll"
              aria-selected="false"
            >
              <i class="nav-icon i-Bird-Delivering-Letter mr-1"></i> Change
              Password
            </a>
          </li>
        </ul>

        <Profile
          v-if="visibleSection === 'Profile'"
          :user="loggedInUser"
          :disableFields="disableFields"
          @toggle-edit-profile="toggleEditProfile"
        />
        <PasswordChange v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Profile from "../../components/Auth/Profile";
import PasswordChange from "../../components/Auth/PasswordChange";

export default {
  components: {
    PasswordChange,
    Profile
  },
  data() {
    return {
      disableFields: true,
      visibleSection: "Profile"
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    ...mapActions(["fetchUser"]),
    toggleEditProfile() {
      this.disableFields = !this.disableFields;
    },
    toggleVisibleSection(section) {
      this.visibleSection = section;
    }
  }
};
</script>
