<template>
  <div>
    <div class="card-title mb-3 text-left">Profile</div>
    <form @submit.prevent="UpdateProfile">
      <div class="row text-left">
        <div class="col-md-6 form-group mb-3">
          <label for="firstName1">First name</label>
          <input
            type="text"
            class="form-control"
            id="firstName1"
            v-model="user.first_name"
            placeholder="Enter your first name"
            :disabled="disableFields"
          />
        </div>

        <div class="col-md-6 form-group mb-3">
          <label for="lastName1">Last name</label>
          <input
            type="text"
            class="form-control"
            id="lastName1"
            v-model="user.last_name"
            placeholder="Enter your last name"
            :disabled="disableFields"
          />
        </div>

        <div class="col-md-6 form-group mb-3">
          <label for="exampleInputEmail1">Email address</label>
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            v-model="user.email"
            placeholder="Enter email"
            disabled
          />
          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
        </div>

        <div class="col-md-6 form-group mb-3">
          <label for="phone">Phone</label>
          <input
            class="form-control"
            id="phone"
            placeholder="Enter phone"
            v-model="user.phone"
            :disabled="disableFields"
          />
        </div>

        <div class="col-md-6 form-group mb-3">
          <label for="picker3">Birth date</label>
          <div class="input-group">
            <flat-pickr
              v-model="user.date_of_birth"
              class="form-control"
              :disabled="disableFields"
            ></flat-pickr>
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button">
                <i class="icon-regular i-Calendar-4"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-6 form-group mb-3">
          <label for="picker1">Gender</label>
          <select
            class="form-control"
            v-model="user.gender"
            :disabled="disableFields"
          >
            <option value="">Gender</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>

        <div v-if="!disableFields" class="col-md-12 text-center my-3">
          <button
            class="btn btn-primary w-50 font-weight-bold text-uppercase"
            :disabled="disableFields"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "Profile",
  components: {
    flatPickr
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    disableFields: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  methods: {
    ...mapActions(["fetchUser"]),
    async UpdateProfile() {
      try {
        axios.put("v1/auth/users/me/", this.user).then(response => {
          if (response.status === 200) {
            this.$swal("Success", "Profile Updated successfully.", "success");
            this.$emit("toggle-edit-profile");
            this.fetchUser();
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
