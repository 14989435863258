<template>
  <div>
    <div class="container">
      <b-form
        method="post"
        @submit.prevent="updateUserPassword"
        class="mx-auto"
      >
        <b-form-row class="mb-2" md="auto">
          <b-col cols="12" md="12">
            <b-input-group
              class="mt-3"
              ref="old-password"
              prepend="Old Password"
            >
              <b-form-input
                v-model="user.currentPassword"
                :type="passFieldType"
                required
              ></b-form-input>
              <b-input-group-append>
                <div class="input-group-append showPassDiv">
                  <i
                    :class="[
                      'form-control show_password_icon',
                      showPass ? 'i-Eye-Scan' : 'i-Eye-Visible'
                    ]"
                    @click="showPassword"
                  ></i>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-form-row>
        <b-form-row class="mb-2" md="auto">
          <b-col cols="12" md="12">
            <b-input-group
              class="mt-3"
              ref="new-password"
              prepend="New Password"
            >
              <b-form-input
                v-model="user.newPassword"
                :type="passConfirmFieldType"
                required
              ></b-form-input>
              <b-input-group-append>
                <div class="input-group-append showPassDiv">
                  <i
                    :class="[
                      'form-control show_password_icon',
                      showConfirmPass ? 'i-Eye-Scan' : 'i-Eye-Visible'
                    ]"
                    @click="showConfirmPassword"
                  ></i>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-form-row>
        <div class="t-center my-4">
          <b-button class="w-50" variant="success" type="submit">
            <span class="mx-auto font-weight-bold text-uppercase">
              Update Password
            </span>
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordChange",
  data() {
    return {
      user: {
        currentPassword: "",
        newPassword: ""
      },
      showPass: false,
      showConfirmPass: false,
      passFieldType: "password",
      passConfirmFieldType: "password"
    };
  },
  methods: {
    clearForm() {
      this.user.currentPassword = "";
      this.user.newPassword = "";
    },
    showPassword() {
      this.showPass = !this.showPass;
    },
    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },
    async updateUserPassword() {
      const data = {
        current_password: this.user.currentPassword,
        new_password: this.user.newPassword
      };
      try {
        await axios.post("v1/auth/users/set_password/", data).then(response => {
          if (response.status === 204) {
            this.$swal(
              "Success",
              `Password reset has been sent to your inbox.`,
              "success"
            );
            this.clearForm();
          }
        });
      } catch (e) {
        if (e.response.data.detail) {
          this.$toast.info(e.response.data.detail);
        } else {
          this.$toast.error("Could not update password at this time");
        }
        this.clearForm();
      }
    }
  },
  watch: {
    showPass() {
      if (this.showPass === true) {
        this.passFieldType = "text";
      } else {
        this.passFieldType = "password";
      }
    },
    showConfirmPass() {
      if (this.showConfirmPass === true) {
        this.passConfirmFieldType = "text";
      } else {
        this.passConfirmFieldType = "password";
      }
    }
  }
};
</script>
